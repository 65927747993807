<script lang="ts" setup>
import type { MarketingBadge, MarketingFlag, OfferFull, OfferMedium } from '~/types/Catalog';

const props = defineProps<{ offer: OfferMedium | OfferFull; usedIn: 'category' | 'offer' }>();

const selectedFlag = ref<MarketingFlag | null>(null);
const isShowFlagModal = ref(false);

watch(
  () => selectedFlag.value,
  () => (isShowFlagModal.value = !!selectedFlag.value),
);

function checkFlagDisplay(flag: MarketingFlag): boolean {
  switch (props.usedIn) {
    case 'category':
      return flag.show_in_categories ?? true;
    case 'offer':
      return flag.show_in_offer ?? true;
    default:
      return true;
  }
}

const freeDelivery = computed(() =>
  props.offer.marketing_flags.find((flag) => flag.title.toLowerCase().trim() === 'бесплатная доставка'),
);

const flagsWithoutFreeDelivery = computed(() =>
  props.offer.marketing_flags.filter((flag) => flag.title.toLowerCase() !== freeDelivery.value?.title.toLowerCase()),
);

function getBadgeTitle(badge: MarketingBadge) {
  if (badge.slug !== 'sale' || !props.offer.old_price) return badge.title;

  const discount = 100 - (100 / props.offer.old_price) * props.offer.price;
  const roundedDiscount = Math.round(discount / 5) * 5;

  return `-${roundedDiscount}%`;
}
</script>

<template>
  <section class="product-image-badges">
    <component
      :is="freeDelivery.description ? 'button' : 'div'"
      v-if="freeDelivery && checkFlagDisplay(freeDelivery)"
      :style="{
        'border-color': freeDelivery.border_color ?? '#393d46',
        'background-color': freeDelivery.background_color ?? 'transparent',
        color: freeDelivery.text_color ?? '#393d46',
      }"
      class="product-image-badges__item"
      @click.stop.prevent="selectedFlag = freeDelivery"
    >
      {{ freeDelivery.title }}
    </component>

    <div
      v-for="badge in offer.marketing_badges"
      :key="badge.title"
      :style="{
        'border-color': badge.border_color ?? '#393d46',
        'background-color': badge.background_color ?? 'transparent',
        color: badge.text_color ?? '#393d46',
      }"
      class="product-image-badges__item"
    >
      {{ getBadgeTitle(badge) }}
    </div>

    <template
      v-for="flag in flagsWithoutFreeDelivery"
      :key="flag.title"
    >
      <component
        :is="flag.description ? 'button' : 'div'"
        v-if="checkFlagDisplay(flag)"
        :style="{
          'border-color': flag.border_color ?? '#393d46',
          'background-color': flag.background_color ?? 'transparent',
          color: flag.text_color ?? '#393d46',
        }"
        class="product-image-badges__item"
        @click.stop.prevent="selectedFlag = flag"
      >
        {{ flag.title }}
      </component>
    </template>

    <BaseModal
      v-model="isShowFlagModal"
      :title="selectedFlag?.title"
      confirm-btn-text="Понятно"
      @close="selectedFlag = null"
      @confirm="selectedFlag = null"
    >
      <div v-html="selectedFlag?.description" />
    </BaseModal>
  </section>
</template>

<style lang="scss" scoped>
.product-image-badges {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__item {
    width: fit-content;
    padding: 2px;
    border: 2px solid;
    border-radius: 4px;
    font-weight: $medium;
    font-size: $text-2xs;
  }
}
</style>
