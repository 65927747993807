<script lang="ts" setup>
import type { OfferImage, OfferMedium } from '~/types/Catalog';

const props = defineProps<{ offer: OfferMedium; recommendationsBlockId?: string }>();

const images = ref<OfferImage[]>(
  [...props.offer.offer_images, ...props.offer.product_images].sort((a, b) => Number(b.is_main) - Number(a.is_main)),
);

function goToReviews() {
  navigateTo({ path: props.offer.uri, hash: '#reviews' });
}
</script>

<template>
  <CardBlock class="product-card">
    <div class="relative">
      <ProductCardImage
        :alt="offer.product_name"
        :images
        class="pt-5"
      />

      <BadgesAndFlagsBlock
        :offer
        class="top-0 left-0"
        used-in="category"
      />

      <FavoriteBtn
        :offer
        class="product-card__favorite-btn"
      />
    </div>

    <section class="product-card__info">
      <div class="flex justify-between flex-wrap text-sm">
        <AvailabilityIndicator :offer />

        <button
          v-if="Number(offer.rating) > 0"
          class="flex items-center gap-1 transition hover:underline"
          @click.stop.prevent="goToReviews"
        >
          <SvgoStar
            class="text-hell-black text-xs"
            filled
          />
          {{ Number(offer.rating).toFixed(1) }}
        </button>
      </div>

      <div class="flex-grow transition leading-5">
        {{ offer.product_name_medium }}
      </div>

      <div class="product-card__footer">
        <div class="flex flex-col gap-1">
          <div
            v-if="offer.old_price"
            class="line-through text-hell-black-60"
          >
            {{ `${offer.old_price.toLocaleString('ru-RU')} ₽` }}
          </div>

          <div class="font-bold">
            {{ `${offer.price.toLocaleString('ru-RU')} ₽` }}
          </div>
        </div>

        <ProductCardCartInteractionBlock
          :offer
          :recommendations-block-id="recommendationsBlockId"
        />
      </div>

      <AdminOfferAvailabilityBlock :offer />
    </section>
  </CardBlock>
</template>

<style lang="scss" scoped>
.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  height: auto;
  border: 1px solid transparent;
  font-size: $text-sm;
  transition: border-color 0.3s;

  @include screen-sm {
    gap: 16px;
  }

  &:hover {
    border-color: $hell-black-20;

    a {
      color: $hell-black;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    font-size: $text-sm;

    @include screen-md {
      font-size: $text-base;
    }
  }

  &__favorite-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
    height: 32px;
    font-size: $text-sm;

    @include screen-sm {
      gap: 16px;
    }

    @include screen-md {
      font-size: $text-base;
    }
  }
}
</style>
