<script lang="ts" setup>
import type { OfferImage } from '~/types/Catalog';

const props = defineProps<{ images: OfferImage[]; alt: string }>();

const image = computed(() => {
  return props.images.find((i) => i.is_main) || props.images[0];
});
</script>

<template>
  <picture
    v-if="images.length"
    class="product-card-image"
  >
    <source
      v-if="image.conversions_webp.thumb_url_180"
      :srcset="`${image.conversions_webp.thumb_url_180}, ${image.conversions_webp.thumb_url_360} 2x`"
      media="(max-width: 640px)"
      type="image/webp"
    />

    <source
      v-if="image.conversions_webp.thumb_url_360"
      :srcset="`${image.conversions_webp.thumb_url_360}, ${image.conversions_webp.thumb_url_740} 2x`"
      media="(min-width: 641px) and (max-width: 1023px)"
      type="image/webp"
    />

    <source
      v-if="image.conversions_webp.thumb_url_180"
      :srcset="`${image.conversions_webp.thumb_url_180}, ${image.conversions_webp.thumb_url_360} 2x`"
      media="(min-width: 1024px)"
      type="image/webp"
    />

    <source
      v-if="image.conversions.thumb_url_360"
      :srcset="image.conversions.thumb_url_360"
      type="image/jpeg"
    />

    <img
      :alt
      :src="image.url"
      class="w-full h-full object-contain"
      loading="lazy"
    />
  </picture>

  <div
    v-else
    class="product-card-image"
  >
    <SvgoNoPhotoBig
      :font-controlled="false"
      class="w-16 h-16"
      filled
    />
  </div>
</template>

<style lang="scss" scoped>
.product-card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 280px;
  border-radius: 12px;
  background-color: $white;
  aspect-ratio: 1/1;
}
</style>
