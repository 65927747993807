<script lang="ts" setup>
import type { OfferFull, OfferMedium } from '~/types/Catalog';

const props = defineProps<{ offer: OfferMedium | OfferFull }>();
const catalogStore = useCatalogStore();
const { sendOfferEventToDataLayer } = useGtmModule();

const isAddedToFavorites = computed(() => catalogStore.favoriteOffersIds.includes(props.offer.id));
const isLoading = ref(false);

const computedClasses = computed(() => [{ 'favorite-btn__icon--active': isAddedToFavorites.value }]);

async function switchFavoriteState() {
  if (isLoading.value) return;

  isLoading.value = true;

  await catalogStore.toggleFavorite(props.offer.id);

  sendOfferEventToDataLayer(isAddedToFavorites.value ? 'add_to_wishlist' : 'remove_from_wishlist', props.offer);
  window.roistat?.event?.send(isAddedToFavorites.value ? 'add_to_wishlist' : 'remove_from_wishlist');
  if (window.r46) window.r46('track', isAddedToFavorites.value ? 'wish' : 'remove_wish', props.offer.id);

  isLoading.value = false;
}
</script>

<template>
  <button
    :disabled="isLoading"
    name="favorite-btn"
    @click.stop.prevent="switchFavoriteState"
  >
    <SvgoHeart
      :class="computedClasses"
      class="favorite-btn__icon"
      filled
    />
  </button>
</template>

<style lang="scss" scoped>
.favorite-btn {
  &__icon {
    width: 24px;
    height: 24px;
    color: $hell-black-40;
    fill: $white;
    transition: all 0.2s;

    &:hover {
      @include screen-md {
        fill: $hell-black-10;
      }
    }

    &--active {
      fill: $label-red;

      &:hover {
        @include screen-md {
          fill: $label-red-dark;
        }
      }
    }
  }
}
</style>
