<script lang="ts" setup>
import { useToast } from 'vue-toastification';
import ToastComponent from '~/components/ui/ToastComponent.vue';
import type { OfferBalance, OfferMedium, OfferSmall } from '~/types/Catalog';

const props = defineProps<{ offer: OfferMedium; recommendationsBlockId?: string }>();

const cartStore = useCartStore();
const route = useRoute();

const isShowModal = ref(false);
const selectedOffer = ref(props.offer.offers.find((o) => o.id === props.offer.id) ?? props.offer.offers[0]);

const offerForDataLayer = computed(() => ({
  ...selectedOffer.value,
  brand: props.offer.brand,
  displayable_offer_properties: props.offer.displayable_offer_properties,
  price: props.offer.price,
}));

const isSelectedOfferContainSizes = computed(() =>
  selectedOffer.value.displayable_offer_properties.some((prop) => ['Размер основной', 'Размер'].includes(prop.name)),
);

onMounted(() => {
  if (props.offer.offers.length && isSelectedOfferContainSizes.value) {
    const parentOfferColor = getOfferColor(selectedOffer.value);

    for (const i in props.offer.offers) {
      if (props.offer.offers[i] && getOfferColor(props.offer.offers[i]) === parentOfferColor) {
        const isOfferInCart = cartStore.mappedCartItems.map((item) => item.id).includes(props.offer.offers[i].id);

        if (isOfferInCart) {
          setSelectedOffer(props.offer.offers[i]);
          break;
        }
      }
    }
  }
});

function setSelectedOffer(offer: OfferSmall) {
  selectedOffer.value = offer;
}

const countInCart = computed(() => {
  const selectedOfferInCart = cartStore.mappedCartItems.find((item) => item.id === selectedOffer.value.id);

  return selectedOfferInCart?.count ?? 0;
});

const selectedOfferAvailableCount = computed(() => {
  let count = 0;

  selectedOffer.value.offer_balances.forEach((balance: OfferBalance) => {
    if (balance.warehouse.delivery_available) count += balance.balance;
  });

  return count;
});

function checkIsAvailableOnlyOffline(offer: OfferSmall) {
  let count = 0;

  offer.offer_balances.forEach((balance: OfferBalance) => {
    if (balance.warehouse.delivery_available) count += balance.balance;
  });

  return count < 1 && offer.offer_balances.some((balance: OfferBalance) => balance.balance > 0);
}

const isNotAvailableToIncrement = computed(() => countInCart.value >= selectedOfferAvailableCount.value);

const isLoading = ref('');
const { sendOfferEventToDataLayer } = useGtmModule();

async function onModalConfirm() {
  if (isLoading.value) return;

  isShowModal.value = false;
  isLoading.value = 'increment';

  const isAddedToCart = await cartStore.addToCart(selectedOffer.value.id);

  if (isAddedToCart) {
    await sendOfferEventToDataLayer('add_to_cart', offerForDataLayer.value as unknown as OfferMedium);

    if (route.name === 'cart') await cartStore.getCart();
  }

  isLoading.value = '';
}

async function addToCart() {
  const toast = useToast();

  if (checkIsAvailableOnlyOffline(selectedOffer.value)) {
    toast.error({
      component: ToastComponent,
      props: { text: 'Товар доступен только для покупки в розничном магазине' },
    });
    return;
  }

  if (props.offer.offers.length) {
    if (isSelectedOfferContainSizes.value) {
      isShowModal.value = true;
    } else {
      if (isLoading.value) return;

      isLoading.value = 'increment';

      const isAddedToCart = await cartStore.addToCart(selectedOffer.value.id);

      if (isAddedToCart) {
        await sendOfferEventToDataLayer('add_to_cart', offerForDataLayer.value as unknown as OfferMedium);
        window.roistat?.event?.send('add_to_cart');
        if (window.r46) {
          const blockId = props.recommendationsBlockId ?? route.query.recommended_code;
          const params = blockId
            ? { id: selectedOffer.value.id, recommended_by: 'dynamic', recommended_code: blockId, amount: 1 }
            : { id: selectedOffer.value.id, amount: 1 };

          window.r46('track', 'cart', params);
        }

        if (route.name === 'cart') await cartStore.getCart();
      }

      isLoading.value = '';
    }
  }
}

async function incrementCountInCart() {
  if (isLoading.value) return;

  isLoading.value = 'increment';

  const isSuccess = await cartStore.increment(selectedOffer.value.id);

  if (isSuccess) {
    await sendOfferEventToDataLayer('add_to_cart', offerForDataLayer.value as unknown as OfferMedium);
    if (window.r46) {
      const item = cartStore.mappedCartItems.find((i) => i.id === selectedOffer.value.id);
      const blockId = props.recommendationsBlockId ?? route.query.recommended_code;
      const params = blockId
        ? { id: selectedOffer.value.id, recommended_by: 'dynamic', recommended_code: blockId, amount: item?.count }
        : { id: selectedOffer.value.id, amount: item?.count };

      window.r46('track', 'cart', params);
    }

    if (route.name === 'cart') await cartStore.getCart();
  }

  isLoading.value = '';
}

async function decrementCountInCart() {
  if (isLoading.value) return;

  isLoading.value = 'decrement';

  const isSuccess = await cartStore.decrement(selectedOffer.value.id);

  if (isSuccess) {
    if (countInCart.value === 0) {
      await sendOfferEventToDataLayer('remove_from_cart', offerForDataLayer.value as unknown as OfferMedium);
      window.roistat?.event?.send('remove_from_cart');
    }

    if (route.name === 'cart') await cartStore.getCart();
  }

  isLoading.value = '';
}

function getOfferColor(offer: OfferSmall): string {
  return offer.displayable_offer_properties.find((p) => p.name === 'Цвет')?.value ?? '';
}

const filteredAndSortedOffers = computed(() => {
  let result: OfferSmall[];

  const selectedColor = getOfferColor(selectedOffer.value);

  result = props.offer.offers.filter((offer) => getOfferColor(offer) === selectedColor);

  if (result.every((offer) => offer.size_charts_values?.length)) {
    result.sort((a, b) => a.size_charts_values[0].order - b.size_charts_values[0].order);
  }

  return result;
});
</script>

<template>
  <div class="flex justify-center items-center">
    <template v-if="countInCart">
      <ProductCardCounterBlock
        :count-in-cart="countInCart"
        :is-loading="isLoading"
        :is-not-available-to-increment="isNotAvailableToIncrement"
        @decrement="decrementCountInCart"
        @increment="incrementCountInCart"
      />
    </template>

    <button
      v-else
      :disabled="Boolean(isLoading)"
      class="add-to-cart-btn"
      @click.stop.prevent="addToCart"
    >
      <SvgoCart
        class="text-xl"
        filled
      />
    </button>

    <Transition
      v-if="countInCart === 0 && Boolean(isLoading)"
      class="w-5 ml-1"
      name="loader"
    >
      <SvgoSpinner
        class="animate-spin"
        title="Идет загрузка..."
      />
    </Transition>

    <BaseModal
      v-model="isShowModal"
      confirm-btn-icon="Cart"
      confirm-btn-text="В корзину"
      title="Варианты"
      @close="isShowModal = false"
      @confirm="onModalConfirm"
    >
      <div class="flex items-center gap-4">
        <picture
          v-if="offer.offer_images[0].url"
          class="cart-list-item__image"
        >
          <source
            v-if="offer.offer_images[0].conversions_webp.thumb_url_180"
            :srcset="`${offer.offer_images[0].conversions_webp.thumb_url_180}, ${offer.offer_images[0].conversions_webp.thumb_url_360} 2x`"
            media="(max-width: 360px)"
            type="image/webp"
          />

          <source
            v-if="offer.offer_images[0].conversions_webp.thumb_url_360"
            :srcset="`${offer.offer_images[0].conversions_webp.thumb_url_360}, ${offer.offer_images[0].conversions_webp.thumb_url_740} 2x`"
            type="image/webp"
          />

          <source
            v-if="offer.offer_images[0].conversions.thumb_url_360"
            :srcset="offer.offer_images[0].conversions.thumb_url_360"
            type="image/jpeg"
          />

          <img
            :alt="offer.product_name"
            :src="offer.offer_images[0].url"
            class="w-full h-full object-contain"
            loading="lazy"
          />
        </picture>

        <span class="flex-shrink">{{ offer.product_name }}</span>

        <span class="flex-shrink-0 font-bold">{{ offer.price.toLocaleString('ru-RU') }} ₽</span>
      </div>

      <ul>
        <li
          v-for="smallOffer in filteredAndSortedOffers"
          :key="smallOffer.id"
          :class="{
            'font-bold': smallOffer.id === selectedOffer.id,
            'offer-list-item--disabled':
              checkIsAvailableOnlyOffline(smallOffer) ||
              smallOffer.offer_balances.every((balance) => balance.balance === 0),
          }"
          class="offer-list-item"
          @click="setSelectedOffer(smallOffer)"
        >
          <button>
            {{
              smallOffer.displayable_offer_properties.find((prop) => ['Размер основной', 'Размер'].includes(prop.name))
                ?.value
            }}
          </button>

          <SvgoCheck
            v-if="smallOffer.id === selectedOffer.id"
            class="text-2xl"
            filled
          />
        </li>
      </ul>
    </BaseModal>
  </div>
</template>

<style lang="scss" scoped>
.add-to-cart-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: transparent;
  transition: background-color 0.3s;

  &:hover {
    background-color: $hell-black-5;
  }

  &:disabled {
    background-color: $hell-black-10;
    color: $hell-black-40;
  }
}

.offer-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 1rem 0;
  border-bottom: 1px solid $hell-black-20;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: $hell-black-60;
  }

  &--disabled {
    color: $hell-black-40;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
